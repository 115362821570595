import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { navigate } from 'gatsby'
import { useAccountContext } from 'src/components/account/context'

import storeConfig from '../../store.config'

const isBrowser = typeof window !== `undefined`

function Page() {
  const { isLoggedIn } = useAccountContext()

  if (!isBrowser) {
    return null
  }

  if (!isLoggedIn) {
    window.location.href = storeConfig.loginUrl

    return null
  }

  navigate('/')

  return (
    <>
      <GatsbySeo />

      <div>loading...</div>
    </>
  )
}

export default Page
